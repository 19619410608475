import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import { withLoading } from '@app/hocs/withLoading.hoc';
import { useResponsive } from '@app/hooks/useResponsive';
import { PromotionTypeEnum } from '@app/types/promotionTypes';
import { SaleTypeEnum } from '@app/types/salesTypes';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import DelayWrapper from '../common/DelayWrapper';

const ManageParks = withLoading(React.lazy(() => import('@app/pages/ManageParkPage/ManageParks')));
const ViewParks = withLoading(React.lazy(() => import('@app/pages/ManageParkPage/components/ParksCard')));
const EditPark = withLoading(React.lazy(() => import('@app/pages/ManageParkPage/components/EditPark')));
const CreatePark = withLoading(React.lazy(() => import('@app/pages/ManageParkPage/components/CreatePark')));
const ViewPark = withLoading(React.lazy(() => import('@app/pages/ManageParkPage/components/ViewPark')));

const ManageLots = withLoading(React.lazy(() => import('@app/pages/ManageLotPage/ManageLots')));
const ViewLots = withLoading(React.lazy(() => import('@app/pages/ManageLotPage/components/ViewLots')));
const MobileViewLots = withLoading(React.lazy(() => import('@app/pages/ManageLotPage/components/MobileViewLots')));
const EditLot = withLoading(React.lazy(() => import('@app/pages/ManageLotPage/components/EditLot')));

const ManageMiscellaneousProducts = withLoading(
  React.lazy(() => import('@app/pages/ManageMiscellaneousProductPage/ManageMiscellaneousProducts')),
);
const ViewMiscellaneousProducts = withLoading(
  React.lazy(() => import('@app/pages/ManageMiscellaneousProductPage/components/ViewMiscellaneousProducts')),
);
const MobileViewMiscellaneousProducts = withLoading(
  React.lazy(() => import('@app/pages/ManageMiscellaneousProductPage/components/MobileViewMiscellaneousProducts')),
);
const CreateMiscellaneousProduct = withLoading(
  React.lazy(() => import('@app/pages/ManageMiscellaneousProductPage/components/CreateMiscellaneousProduct')),
);

const ManageSales = withLoading(React.lazy(() => import('@app/pages/ManageSalePage/ManageSales')));
const ViewSales = withLoading(React.lazy(() => import('@app/pages/ManageSalePage/components/ViewSales')));
const MobileViewSales = withLoading(React.lazy(() => import('@app/pages/ManageSalePage/components/MobileViewSales')));
const CreateSale = withLoading(React.lazy(() => import('@app/pages/ManageSalePage/components/CreateSale')));
const ProcessSale = withLoading(React.lazy(() => import('@app/pages/ManageSalePage/components/ProcessSale')));

const ManageMiscellaneousSales = withLoading(
  React.lazy(() => import('@app/pages/ManageMiscellaneousSalePage/ManageMiscellaneousSales')),
);
const ViewMiscellaneousSales = withLoading(
  React.lazy(() => import('@app/pages/ManageMiscellaneousSalePage/components/ViewMiscellaneousSales')),
);
const MobileViewMiscellaneousSales = withLoading(
  React.lazy(() => import('@app/pages/ManageMiscellaneousSalePage/components/MobileViewMiscellaneousSales')),
);
const CreateMiscellaneousSale = withLoading(
  React.lazy(() => import('@app/pages/ManageMiscellaneousSalePage/components/CreateMiscellaneousSale')),
);

const CheckoutInfo = withLoading(React.lazy(() => import('@app/pages/ManageSalePage/components/PaymentInfo')));
const LinkPayment = withLoading(React.lazy(() => import('@app/pages/ManagePaymentPage/components/LinkPayment')));
const LinkPaymentCancel = withLoading(
  React.lazy(() => import('@app/pages/ManagePaymentPage/components/LinkPaymentCancel')),
);
const RedirectPayment = withLoading(
  React.lazy(() => import('@app/pages/ManagePaymentPage/components/RedirectPayment')),
);

const ManageApprovals = withLoading(React.lazy(() => import('@app/pages/ManageApprovalPage/ManageApprovals')));
const ViewApprovals = withLoading(React.lazy(() => import('@app/pages/ManageApprovalPage/components/ViewApprovals')));
const MobileViewApprovals = withLoading(
  React.lazy(() => import('@app/pages/ManageApprovalPage/components/MobileViewApprovals')),
);
const ViewApproval = withLoading(React.lazy(() => import('@app/pages/ManageApprovalPage/components/ViewApproval')));

const ManageApprovalMatrices = withLoading(
  React.lazy(() => import('@app/pages/ManageApprovalMatricesPage/ManageApprovalMatrices')),
);
const ViewAprovalMatrix = withLoading(
  React.lazy(() => import('@app/pages/ManageApprovalMatricesPage/components/ViewApprovalMatrix')),
);

const ManagePayments = withLoading(React.lazy(() => import('@app/pages/ManagePaymentPage/ManagePayments')));
const ViewPayments = withLoading(React.lazy(() => import('@app/pages/ManagePaymentPage/components/ViewPayments')));
const MobileViewPayments = withLoading(
  React.lazy(() => import('@app/pages/ManagePaymentPage/components/MobileViewPayments')),
);
const ViewPaymentBreakdown = withLoading(
  React.lazy(() => import('@app/pages/ManagePaymentPage/components/ViewPaymentBreakdown')),
);

const ManageRefunds = withLoading(React.lazy(() => import('@app/pages/ManageRefundPage/ManageRefunds')));
const ViewRefunds = withLoading(React.lazy(() => import('@app/pages/ManageRefundPage/components/ViewRefunds')));
const MobileViewRefunds = withLoading(
  React.lazy(() => import('@app/pages/ManageRefundPage/components/MobileViewRefunds')),
);
const ViewRefund = withLoading(React.lazy(() => import('@app/pages/ManageRefundPage/components/ViewRefund')));

const ManageVerifications = withLoading(
  React.lazy(() => import('@app/pages/ManageVerificationPage/ManageVerifications')),
);
const ViewVerifications = withLoading(
  React.lazy(() => import('@app/pages/ManageVerificationPage/components/ViewVerifications')),
);

const ManageAgents = withLoading(React.lazy(() => import('@app/pages/ManageAgentPage/ManageAgents')));
const ViewAgents = withLoading(React.lazy(() => import('@app/pages/ManageAgentPage/components/ViewAgents')));
const MobileViewAgents = withLoading(
  React.lazy(() => import('@app/pages/ManageAgentPage/components/MobileViewAgents')),
);

const ManagePromotions = withLoading(React.lazy(() => import('@app/pages/ManagePromotionPage/ManagePromotions')));
const ViewPromotions = withLoading(
  React.lazy(() => import('@app/pages/ManagePromotionPage/components/ViewPromotions')),
);
const MobileViewPromotions = withLoading(
  React.lazy(() => import('@app/pages/ManagePromotionPage/components/MobileViewPromotions')),
);
const CreatePromotion = withLoading(
  React.lazy(() => import('@app/pages/ManagePromotionPage/components/CreatePromotion')),
);
const MobileViewPromotion = withLoading(
  React.lazy(() => import('@app/pages/ManagePromotionPage/components/MobileViewPromotion')),
);

const ManageCompanies = withLoading(React.lazy(() => import('@app/pages/ManageCompaniesPage/ManageCompanies')));
const ViewCompanies = withLoading(React.lazy(() => import('@app/pages/ManageCompaniesPage/components/ViewCompanies')));
const CreateCompany = withLoading(React.lazy(() => import('@app/pages/ManageCompaniesPage/components/CreateCompany')));
const MobileViewCompany = withLoading(
  React.lazy(() => import('@app/pages/ManageCompaniesPage/components/MobileViewCompany')),
);

const ManageCustomers = withLoading(React.lazy(() => import('@app/pages/ManageCustomersPage/ManageCustomers')));
const ViewCustomers = withLoading(React.lazy(() => import('@app/pages/ManageCustomersPage/components/ViewCustomers')));
const MobileViewCustomers = withLoading(
  React.lazy(() => import('@app/pages/ManageCustomersPage/components/MobileViewCustomers')),
);
const CreateEditCustomer = withLoading(
  React.lazy(() => import('@app/pages/ManageCustomersPage/components/CreateEditCustomer')),
);

const ManageStaff = withLoading(React.lazy(() => import('@app/pages/ManageStaffPage/ManageStaff')));
const ViewStaffs = withLoading(React.lazy(() => import('@app/pages/ManageStaffPage/components/ViewStaffs')));
const MobileViewStaffs = withLoading(
  React.lazy(() => import('@app/pages/ManageStaffPage/components/MobileViewStaffs')),
);

const ManageCommissions = withLoading(React.lazy(() => import('@app/pages/ManageCommissionsPage/ManageCommissions')));
const ViewCommissions_ProductValue = withLoading(
  React.lazy(() => import('@app/pages/ManageCommissionsPage/components/ProductValue')),
);
const ViewCommissions_TiersAndOthers = withLoading(
  React.lazy(() => import('@app/pages/ManageCommissionsPage/components/TiersAndOthers/TiersAndOthers')),
);

const ManageIncentive = withLoading(React.lazy(() => import('@app/pages/ManageIncentivePage/ManageIncentives')));
const ViewIncentive_CompanyTargets = withLoading(
  React.lazy(() => import('@app/pages/ManageIncentivePage/components/CompanyTargets')),
);
const ViewIncentive_Channels = withLoading(
  React.lazy(() => import('@app/pages/ManageIncentivePage/components/Channels')),
);

const Register = withLoading(React.lazy(() => import('@app/pages/Register/Register')));
const Login = withLoading(React.lazy(() => import('@app/pages/Login/Login')));
const Reset = withLoading(React.lazy(() => import('@app/pages/ResetPassword/ResetPassword')));
const Error404 = withLoading(React.lazy(() => import('@app/components/common/Error404')));

export const AppRouter: React.FC = () => {
  const protectedLayout = <MainLayout />;
  const { mobileOnly } = useResponsive();
  const paymentRedirectServerDelay = 5000;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={protectedLayout}>
          <Route index element={<Navigate to="/manage-parks" replace />} />
          <Route path="manage-parks" element={<ManageParks />}>
            <Route path="/manage-parks" element={<ViewParks />} />
            <Route path="/manage-parks/create" element={<CreatePark />} />
            <Route path="/manage-parks/edit/:id" element={<EditPark />} />
            <Route path="/manage-parks/view/:id" element={<ViewPark />} />
          </Route>
          <Route path="manage-lots" element={<ManageLots />}>
            <Route path="/manage-lots" element={mobileOnly ? <MobileViewLots /> : <ViewLots />} />
            <Route path="/manage-lots/edit/:id" element={<EditLot />} />
            <Route
              path="/manage-lots/payment/success/:id"
              element={
                <DelayWrapper waitBeforeShow={paymentRedirectServerDelay}>
                  <EditLot paymentInfoMode="success" />
                </DelayWrapper>
              }
            />
            <Route path="/manage-lots/payment/cancel/:id" element={<EditLot paymentInfoMode="cancel" />} />
            <Route path="/manage-lots/payment/fail/:id" element={<EditLot paymentInfoMode="fail" />} />
          </Route>
          <Route path="manage-miscellaneous-products" element={<ManageMiscellaneousProducts />}>
            <Route
              path="/manage-miscellaneous-products"
              element={mobileOnly ? <MobileViewMiscellaneousProducts /> : <ViewMiscellaneousProducts />}
            />
            <Route path="/manage-miscellaneous-products/create" element={<CreateMiscellaneousProduct />} />
            <Route path="/manage-miscellaneous-products/edit/:id" element={<CreateMiscellaneousProduct editMode />} />
          </Route>
          <Route path="/manage-staff" element={<ManageStaff />}>
            <Route path="/manage-staff" element={mobileOnly ? <MobileViewStaffs /> : <ViewStaffs />} />
          </Route>
          <Route path="manage-agents" element={<ManageAgents />}>
            <Route path="/manage-agents" element={mobileOnly ? <MobileViewAgents /> : <ViewAgents />} />
          </Route>
          <Route path="/manage-customers" element={<ManageCustomers />}>
            <Route path="/manage-customers" element={mobileOnly ? <MobileViewCustomers /> : <ViewCustomers />} />
            <Route path="/manage-customers/create" element={<CreateEditCustomer />} />
            <Route path="/manage-customers/edit/:id" element={<CreateEditCustomer />} />
          </Route>
          <Route path="manage-promotions" element={<ManagePromotions />}>
            <Route path="/manage-promotions" element={mobileOnly ? <MobileViewPromotions /> : <ViewPromotions />} />
            <Route
              path="/manage-promotions/create/lot"
              element={<CreatePromotion promotionType={PromotionTypeEnum.Lot} />}
            />
            <Route
              path="/manage-promotions/create/misc"
              element={<CreatePromotion promotionType={PromotionTypeEnum.Misc} />}
            />
            <Route
              path="/manage-promotions/edit/lot/:id"
              element={
                mobileOnly ? (
                  <MobileViewPromotion promotionType={PromotionTypeEnum.Lot} />
                ) : (
                  <CreatePromotion editPromotionMode promotionType={PromotionTypeEnum.Lot} />
                )
              }
            />
            <Route
              path="/manage-promotions/edit/misc/:id"
              element={
                mobileOnly ? (
                  <MobileViewPromotion promotionType={PromotionTypeEnum.Misc} />
                ) : (
                  <CreatePromotion editPromotionMode promotionType={PromotionTypeEnum.Misc} />
                )
              }
            />
          </Route>
          <Route path="manage-companies" element={<ManageCompanies />}>
            <Route path="/manage-companies" element={<ViewCompanies />} />
            <Route path="/manage-companies/create" element={<CreateCompany />} />
            <Route path="/manage-companies/edit/:id" element={<CreateCompany />} />
            <Route path="/manage-companies/view/:id" element={<MobileViewCompany />} />
          </Route>
          <Route path="manage-logics" element={<ManageApprovalMatrices />}>
            <Route path="/manage-logics" element={<ViewAprovalMatrix />} />
          </Route>
          <Route path="manage-sales" element={<ManageSales />}>
            <Route path="/manage-sales" element={mobileOnly ? <MobileViewSales /> : <ViewSales />} />
            <Route path="/manage-sales/create" element={<CreateSale />} />
            <Route path="/manage-sales/edit/:id" element={<CreateSale editSaleMode />} />
            <Route path="/manage-sales/process/:id" element={<ProcessSale saleType={SaleTypeEnum.LotSale} />} />
            <Route
              path="/manage-sales/payment/success/:id"
              element={
                <DelayWrapper waitBeforeShow={paymentRedirectServerDelay}>
                  <ProcessSale paymentInfoMode="success" saleType={SaleTypeEnum.LotSale} />
                </DelayWrapper>
              }
            />
            <Route
              path="/manage-sales/payment/cancel/:id"
              element={<ProcessSale paymentInfoMode="cancel" saleType={SaleTypeEnum.LotSale} />}
            />
            <Route
              path="/manage-sales/payment/fail/:id"
              element={<ProcessSale paymentInfoMode="fail" saleType={SaleTypeEnum.LotSale} />}
            />
          </Route>
          <Route path="manage-miscellaneous-sales" element={<ManageMiscellaneousSales />}>
            <Route
              path="/manage-miscellaneous-sales"
              element={mobileOnly ? <MobileViewMiscellaneousSales /> : <ViewMiscellaneousSales />}
            />
            <Route path="/manage-miscellaneous-sales/create/:saleType" element={<CreateMiscellaneousSale />} />
            <Route
              path="/manage-miscellaneous-sales/edit/:id"
              element={<CreateMiscellaneousSale editSaleMode draftViewMode />}
            />
            <Route
              path="/manage-miscellaneous-sales/process/:id"
              element={<ProcessSale saleType={SaleTypeEnum.MiscSale} />}
            />
            <Route
              path="/manage-miscellaneous-sales/payment/success/:id"
              element={
                <DelayWrapper waitBeforeShow={paymentRedirectServerDelay}>
                  <ProcessSale paymentInfoMode="success" saleType={SaleTypeEnum.MiscSale} />
                </DelayWrapper>
              }
            />
            <Route
              path="/manage-miscellaneous-sales/payment/cancel/:id"
              element={<ProcessSale paymentInfoMode="cancel" saleType={SaleTypeEnum.MiscSale} />}
            />
            <Route
              path="/manage-miscellaneous-sales/payment/fail/:id"
              element={<ProcessSale paymentInfoMode="fail" saleType={SaleTypeEnum.MiscSale} />}
            />
          </Route>
          <Route path="manage-payments" element={<ManagePayments />}>
            <Route path="/manage-payments" element={mobileOnly ? <MobileViewPayments /> : <ViewPayments />} />
            <Route path="/manage-payments/view/:id" element={<ViewPaymentBreakdown />} />
            <Route
              path="/manage-payments/payment/:id"
              element={
                <DelayWrapper waitBeforeShow={paymentRedirectServerDelay}>
                  <ViewPaymentBreakdown redirectPaymentMode />
                </DelayWrapper>
              }
            />
            <Route path="/manage-payments/cancel/:id" element={<ViewPaymentBreakdown />} />
          </Route>
          <Route path="manage-refunds" element={<ManageRefunds />}>
            <Route path="/manage-refunds" element={mobileOnly ? <MobileViewRefunds /> : <ViewRefunds />} />
            <Route path="/manage-refunds/view/:id" element={<ViewRefund />} />
          </Route>
          <Route path="manage-verifications" element={<ManageVerifications />}>
            <Route path="/manage-verifications" element={<ViewVerifications />} />
          </Route>
          <Route path="manage-commissions" element={<ManageCommissions />}>
            <Route path="/manage-commissions/product-value" element={<ViewCommissions_ProductValue />} />
            <Route path="/manage-commissions/tiers-and-others" element={<ViewCommissions_TiersAndOthers />} />
          </Route>
          {false && (
            <>
              <Route path="manage-incentives" element={<ManageIncentive />}>
                <Route path="/manage-incentives/company-targets" element={<ViewIncentive_CompanyTargets />} />
                <Route path="/manage-incentives/channels" element={<ViewIncentive_Channels />} />
              </Route>
            </>
          )}
          <Route path="manage-approvals" element={<ManageApprovals />}>
            <Route path="/manage-approvals" element={mobileOnly ? <MobileViewApprovals /> : <ViewApprovals />} />
            <Route path="/manage-approvals/view/:id" element={<ViewApproval />} />
          </Route>
        </Route>
        <Route path="/payment/process/:key" element={<RedirectPayment />} />
        <Route path="/payment/:paymentStatus/:id" element={<CheckoutInfo />} />
        <Route path="/payment/:id" element={<LinkPayment />} />
        <Route path="/payment/cancel/:id" element={<LinkPaymentCancel />} />
        <Route path="/signup/:id" element={<Register />} />
        <Route path="/reset-password/:resetKey" element={<Reset />} />
        <Route path="/login" element={<Login />} />
        <Route path="/error404" element={<Error404 />} />
        <Route path="*" element={<Navigate to="/error404" replace />} />
      </Routes>
    </BrowserRouter>
  );
};
