import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as GS from '../../../../../styles/GlobalStyledComponent.style';
import { sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';
import { Badge, MenuProps } from 'antd';
import { GET_APPROVALS_COUNT } from '@app/graphql/queries/approvalQueries';
import { useQuery } from '@apollo/client';
import {
  CheckCircleOutlined,
  CreditCardOutlined,
  // GiftOutlined,
  PercentageOutlined,
  SettingOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { useResponsive } from '@app/hooks/useResponsive';
import { t } from 'i18next';
import { GET_REFUNDS } from '@app/graphql/queries/refundQueries';
import { RefundStatusEnum } from '@app/types/refundTypes';
import { GET_PAYMENT_VERIFICATIONS } from '@app/graphql/queries/paymentQueries';
import { useUserPermission } from '@app/hooks/useUserPermission';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const {
    canNavigateToMiscProductPage,
    canNavigateToCompanyPage,
    canNavigateToApprovalMatrixPage,
    canNavigateToCommissionPage,
  } = useUserPermission();
  const location = useLocation();
  const { desktopOnly } = useResponsive();

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys = openedSubmenu && desktopOnly ? [openedSubmenu.key] : [];

  const pollInterval = 1000 * 300;

  const { data: getApprovalsData } = useQuery(GET_APPROVALS_COUNT, {
    variables: {
      input: {},
    },
    pollInterval,
  });

  const { data: getRefundsData } = useQuery(GET_REFUNDS, {
    variables: {
      input: {
        filter: {
          status: [RefundStatusEnum.Outstanding, RefundStatusEnum.InProcess],
        },
      },
    },
    pollInterval,
  });

  const { data: getPaymentVerificationsData } = useQuery(GET_PAYMENT_VERIFICATIONS, {
    variables: {
      input: {
        filter: {
          myVerifications: true,
        },
      },
    },
    pollInterval,
  });

  const getNotificationCount = (key: string) => {
    const approvalsCount = Number(getApprovalsData?.approvals?.paginationInfo?.totalItems) || 0;
    const refundsCount = Number(getRefundsData?.refunds?.paginationInfo?.totalItems) || 0;
    const paymentVerificationsCount =
      Number(getPaymentVerificationsData?.paymentVerifications?.paginationInfo?.totalItems) || 0;

    if (key == 'transactions-parent') return refundsCount + paymentVerificationsCount;
    if (key == 'refunds') return refundsCount;
    if (key == 'verifications') return paymentVerificationsCount;
    if (key == 'approvals') return approvalsCount;
    return 0;
  };

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    // Makes sub-category allignment the same with its parent category
    if (!icon) icon = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const getBadgeCounter = (key: string) => {
    return (
      <span style={{ float: 'right' }}>
        <Badge
          count={String(getNotificationCount(key))}
          style={{ backgroundColor: 'var(--primary-main)', borderColor: 'transparent' }}
        />
      </span>
    );
  };

  const items: MenuProps['items'] = [
    getItem(t('Administration'), 'administration', <SettingOutlined />, [
      getItem(<Link to="/manage-parks">{t('Park & zones')}</Link>, 'parkandzones'),
      getItem(<Link to="/manage-lots">{t('Lots')}</Link>, 'lots'),
      getItem(<Link to="/manage-miscellaneous-products">{t('Miscellaneous')}</Link>, 'miscellaneous-products'),
      ...(canNavigateToMiscProductPage ? [getItem(<Link to="/manage-staff">{t('Staff')}</Link>, 'staff')] : []),
      getItem(<Link to="/manage-agents">{t('Agents & commissions')}</Link>, 'agentandcommisions'),
      getItem(<Link to="/manage-customers">{t('Customers')}</Link>, 'customers'),
      getItem(<Link to="/manage-promotions">{t('Promotions')}</Link>, 'promotions'),
      ...(canNavigateToCompanyPage ? [getItem(<Link to="/manage-companies">{t('Companies')}</Link>, 'companies')] : []),
      ...(canNavigateToApprovalMatrixPage
        ? [getItem(<Link to="/manage-logics">{t('Approval matrix')}</Link>, 'logic')]
        : []),
    ]),
    getItem(t('Sales'), 'sales-parent', <ShoppingOutlined />, [
      getItem(<Link to="/manage-sales">{t('Lots')}</Link>, 'lot-sales'),
      getItem(<Link to="/manage-miscellaneous-sales">{t('Miscellaneous')}</Link>, 'miscellaneous-sales'),
    ]),
    getItem(
      <>
        {t('Transactions')}
        {getBadgeCounter('transactions-parent')}
      </>,
      'transactions-parent',
      <CreditCardOutlined />,
      [
        getItem(<Link to="/manage-payments">{t('Payments')}</Link>, 'payments'),
        getItem(
          <Link to="/manage-refunds">
            {t('Refunds')}
            {getBadgeCounter('refunds')}
          </Link>,
          'refunds',
        ),
        getItem(
          <Link to="/manage-verifications">
            {t('Verification')}
            {getBadgeCounter('verifications')}
          </Link>,
          'verifications',
        ),
      ],
    ),
    ...(canNavigateToCommissionPage
      ? [
          getItem(
            t('Commissions'),
            'commissions-parent',
            <PercentageOutlined
              {...(desktopOnly && { style: { border: '2px solid', borderRadius: 4, fontSize: 16 } })}
            />,
            [
              getItem(<Link to="/manage-commissions/product-value">{t('Product value')}</Link>, 'product-value'),
              getItem(<Link to="/manage-commissions/tiers-and-others">{t('Tiers & others')}</Link>, 'tiers-and-others'),
            ],
          ),
          // getItem(t('Incentives'), 'incentives-parent', <GiftOutlined />, [
          //   getItem(<Link to="/manage-incentives/company-targets">{t('Company targets')}</Link>, 'company-targets'),
          //   getItem(<Link to="/manage-incentives/channels">{t('Channels')}</Link>, 'channels'),
          //   // getItem(<Link to="/manage-incentives/staff-targets">{t('Staff targets')}</Link>, 'staff-targets'),
          //   // getItem(<Link to="/manage-incentives/tally-and-payouts">{t('Tally & payout')}</Link>, 'tally-and-payouts'),
          // ]),
        ]
      : []),
    getItem(
      <Link to="/manage-approvals">
        {t('Approvals')}
        {getBadgeCounter('approvals')}
      </Link>,
      'approvals',
      <CheckCircleOutlined />,
    ),
  ];

  return (
    <GS.MenuSidebar
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => {
        setCollapsed(true);
      }}
      items={items}
    />
  );
};

export default SiderMenu;
